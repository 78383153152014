import React, { useState } from 'react';
import { Box, Container, Grid, IconButton, Typography, Button, Modal, Accordion, AccordionSummary, AccordionDetails, styled } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const faqs = [
  {
    question: "What is Web3?",
    answer: "It is the evolution of the internet based on blockchain technology, aimed at decentralizing data control, allowing users to directly own and manage their information and digital assets."
  },
  {
    question: "What is a Token?",
    answer: "A token is a digital asset that represents value or rights within a blockchain project. It can be used for transactions, access to services, or as an asset in decentralized ecosystems."
  },
  {
    question: "What is an Airdrop?",
    answer: "An airdrop is the free distribution of tokens to users, usually to promote a project or reward loyalty."
  },
  {
    question: "What is a Miniapp?",
    answer: "A miniapp is a lightweight application that operates within a platform without needing to be downloaded onto a device. Miniapps provide quick and easy access to Web3. A-STYLE uses the Telegram platform."
  },
  {
    question: "What is Tap to Earn?",
    answer: "Tap to Earn is an earning model where users can receive tokens by interacting with a Miniapp. This model requires users to complete simple actions, such as tapping their device's screen, engaging with the community, or performing activities within the app."
  },
  {
    question: "What is the A-STYLE Tap to Earn (T2E) for?",
    answer: "The T2E engages users in the A-STYLE Club in an easy and fun way, rewarding their interaction through a rewards program and the distribution of A-STYLE Tokens (Phase 1). It will become a tool to interact with the physical A-STYLE Web3 product (Phase 2). A-STYLE Tokens are limited in number and are designed to reflect the Brand's growth over time. Their value will be supported by the sales of A-STYLE products."
  },
  {
    question: "What is a Crypto Wallet?",
    answer: "A crypto wallet is a digital wallet that allows you to store, send, and receive tokens and digital assets quickly and securely."
  },
  {
    question: "What does $ASTYLE mean?",
    answer: "The $ symbol before a name indicates the ticker of a token, a short code that identifies it in the digital world."
  },
  {
    question: "When was A-STYLE founded?",
    answer: "A-STYLE is a clothing brand founded in Milan, Italy, in 1999."
  },
  {
    question: "Who is the owner of A-STYLE?",
    answer: "Its founder, Marco Bruns."
  },
  {
    question: "In which countries has A-STYLE been distributed?",
    answer: "Primarily in Europe and Japan."
  },
  {
    question: "Is there a hidden double meaning in the A-STYLE logo?",
    answer: "Maybe..."
  },
  {
    question: "What is the hidden meaning in the A-STYLE logo?",
    answer: "The first letter of the alphabet."
  }
];
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: 'transparent',
  color: '#fff',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: theme.spacing(1, 0),
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  '&.Mui-expanded': {
    minHeight: 48,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2, 2, 3),
}));

export const Footer = () => {
  const navigate = useNavigate();
  const [openFAQ, setOpenFAQ] = useState(false);

  const handleOpenFAQ = () => setOpenFAQ(true);
  const handleCloseFAQ = () => setOpenFAQ(false);

  const redirect = (url: string) => {
    window.location.href = url;
  }

  return (
    <Box
      sx={{
        background: "#111",
        padding: window.innerWidth > 600 ? "8vh 0" : "2vh 1vh",
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                textAlign: { xs: "center", md: "unset" },
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              Social
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: "1vh",
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
              }}
            >
              <Grid item>
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://t.me/astyle_official",
                      "_blank")}
                  sx={{ color: "#fff" }}
                >
                  <TelegramIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/AStyleFashion",
                      "_blank"
                    )
                  }
                  sx={{ color: "#fff" }}
                >
                  <FacebookIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://x.com/ASTYLE_official",
                      "_blank"
                    )
                  }
                  sx={{ color: "#fff" }}
                >
                  <TwitterIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/astyle__official",
                      "_blank"
                    )
                  }
                  sx={{ color: "#fff" }}
                >
                  <InstagramIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                textAlign: { xs: "center", md: "unset" },
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              Contact
            </Typography>
            <Grid
              container
              spacing={2}
              flexDirection="column"
              sx={{
                marginTop: "1vh",
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
              }}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: {
                      xs: "center",
                      md: "unset",
                    },
                  }}
                >
                  <a
                    href="mailto:info@a-style.it"
                    style={{
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    info@a-style.it
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: {
                      xs: "center",
                      md: "unset",
                    },
                  }}
                >
                  <a
                    href="mailto:product@a-style.it"
                    style={{
                      color: "#fff",
                      textDecoration: "none",
                    }}
                  >
                    product@a-style.it
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: { xs: "center", md: "unset" },
              }}
            >
              Policy
            </Typography>
            <Grid
              container
              spacing={2}
              flexDirection="column"
              sx={{
                marginTop: "1vh",
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
              }}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: {
                      xs: "center",
                      md: "unset",
                    },
                  }}
                >
                  <a
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => navigate("terms-condition")}
                  >
                    Terms of use
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: {
                      xs: "center",
                      md: "unset",
                    },
                  }}
                >
                  <a
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={() => navigate("privacy-policy")}
                  >
                    Privacy Policy
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: { xs: "center", md: "unset" },
              }}
            >
              FAQ
            </Typography>
            <Grid
              container
              spacing={2}
              flexDirection="column"
              sx={{
                marginTop: "1vh",
                justifyContent: {
                  xs: "center",
                  md: "flex-start",
                },
              }}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: {
                      xs: "center",
                      md: "unset",
                    },
                  }}
                >
                  <a
                    style={{ color: "#fff", cursor: "pointer" }}
                    onClick={handleOpenFAQ}
                  >
                    Open FAQ
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Modal
        open={openFAQ}
        onClose={handleCloseFAQ}
        aria-labelledby="faq-modal-title"
        aria-describedby="faq-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: '#111',
          border: '1px solid #333',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          overflow: 'auto',
          borderRadius: '8px',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography id="faq-modal-title" variant="h4" component="h2" sx={{ color: '#fff', fontWeight: 'bold' }}>
              FAQ
            </Typography>
            <IconButton onClick={handleCloseFAQ} sx={{ color: '#fff' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          {faqs.map((faq, index) => (
            <StyledAccordion key={index}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography 
                  sx={{ 
                    fontWeight: 'bold', 
                    background: 'linear-gradient(to right, #facc15, #fde047)', 
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {faq.question}
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>{faq.answer}</Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
          ))}
        </Box>
      </Modal>
    </Box>
  );
};